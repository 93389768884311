



































import {Component, Vue} from 'vue-property-decorator';

@Component
export default class PasswordChecker extends Vue {
  private passwordValue = '';
  private passwordError = false;

  mounted(): void {
    (this.$refs.passwordInput as HTMLElement).focus();
  }

  private passwordCheck(): void {
    if (this.passwordValue === process.env.VUE_APP_PASSWORD) {
      this.$emit('loggedIn');
    } else {
      this.passwordError = true;
    }
  }
}
